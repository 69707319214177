var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',{attrs:{"validator":_vm.$v.html,"name":"body","messages":_vm.localMessages}},[_c('div',{class:[
      'editor',
      { 'editor--editable': _vm.editable, editor__error: _vm.$v.$error }
    ]},[(_vm.editable)?_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var commands = ref.commands;
    var isActive = ref.isActive;
return [_c('div',{staticClass:"editor__menubar"},[_c('button',{class:['editor__button', { 'is-active': isActive.image() }],attrs:{"aria-label":"Imagem"},on:{"click":function($event){return _vm.showImagePrompt(commands.image)}}},[_c('font-awesome-icon',{attrs:{"icon":"image"}})],1),_vm._l((_vm.menuBarButtons),function(ref,index){
    var active = ref.active;
    var ariaLabel = ref.ariaLabel;
    var command = ref.command;
    var icon = ref.icon;
    var level = ref.level;
return _c('button',{key:(index + "-" + icon),class:[
            'editor__button',
            {
              'is-active': active && isActive[active]()
            }
          ],attrs:{"aria-label":ariaLabel},on:{"click":function($event){return commands[command]({ level: level })}}},[_c('font-awesome-icon',{attrs:{"icon":icon}})],1)})],2)]}}],null,false,4104384598)}):_vm._e(),_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}})],1),_c('form-group',{attrs:{"validator":_vm.$v.imageSource,"name":"url","messages":_vm.localMessages}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }