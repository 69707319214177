<template>
  <div class="app-header" />
</template>

<script>
export default {
  name: 'TheAppHeader'
}
</script>

<style scoped>
.app-header {
  height: 16vh;
  background: url('../../assets/bg-header.svg') no-repeat center bottom;
  background-size: cover;
  opacity: 0.9;
}
</style>
