var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-side-menu"},[(_vm.showScrollToTopButton)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
      content: "Ir para o início",
      delay: { show: 800 }
    }),expression:"{\n      content: `Ir para o início`,\n      delay: { show: 800 }\n    }",modifiers:{"left":true}}],staticClass:"app-side-menu__wrapper app-side-menu__button",on:{"click":_vm.scrollToTop}},[_c('arrow-icon',{staticClass:"app-side-menu__scroll-top-button "})],1):_vm._e(),(_vm.showPercentScroll)?_c('small',[_vm._v(_vm._s(_vm.percentScroll)+"% ")]):_vm._e(),(_vm.showScrollToBottomButton)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
      content: "Ir para o fim",
      delay: { show: 800 }
    }),expression:"{\n      content: `Ir para o fim`,\n      delay: { show: 800 }\n    }",modifiers:{"left":true}}],staticClass:"app-side-menu__wrapper app-side-menu__button",on:{"click":_vm.scrollToBottom}},[_c('arrow-icon',{staticClass:"app-side-menu__scroll-bottom-button"})],1):_vm._e(),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
      content: "Trocar tema",
      delay: { show: 800 }
    }),expression:"{\n      content: `Trocar tema`,\n      delay: { show: 800 }\n    }",modifiers:{"left":true}}],staticClass:"app-side-menu__wrapper app-side-menu__button",on:{"click":_vm.toggleDarkMode}},[_c('font-awesome-icon',{staticClass:"app-side-menu__icon",attrs:{"icon":"lightbulb","size":"2x"}})],1)},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
      content: "Aumentar fonte",
      delay: { show: 800 }
    }),expression:"{\n      content: `Aumentar fonte`,\n      delay: { show: 800 }\n    }",modifiers:{"left":true}}],staticClass:"app-side-menu__wrapper app-side-menu__button app-side-menu__button--center",on:{"click":_vm.commitArticleFontSizeIncrease}},[_c('font-awesome-icon',{staticClass:"app-side-menu__icon",attrs:{"icon":"font","size":"2x"}}),_c('font-awesome-icon',{staticClass:"app-side-menu__icon app-side-menu__button--sup",attrs:{"icon":"plus"}})],1)},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
      content: "Diminuir fonte",
      delay: { show: 800 }
    }),expression:"{\n      content: `Diminuir fonte`,\n      delay: { show: 800 }\n    }",modifiers:{"left":true}}],staticClass:"app-side-menu__wrapper app-side-menu__button--center app-side-menu__button",on:{"click":_vm.commitArticleFontSizeDecrease}},[_c('font-awesome-icon',{staticClass:"app-side-menu__icon",attrs:{"icon":"font","size":"2x"}}),_c('font-awesome-icon',{staticClass:"app-side-menu__icon app-side-menu__button--sup",attrs:{"icon":"minus"}})],1)},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
      content: "Exibir notificações",
      delay: { show: 800 }
    }),expression:"{\n      content: `Exibir notificações`,\n      delay: { show: 800 }\n    }",modifiers:{"left":true}}],staticClass:"app-side-menu__wrapper app-side-menu__button",on:{"click":_vm.callNotifications}},[_c('font-awesome-icon',{staticClass:"app-side-menu__icon",attrs:{"icon":"bell","size":"2x"}})],1)}]

export { render, staticRenderFns }