var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-view-edit"},[_c('dot-loader',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"profile-view-edit__avatar"},[_c('image-picker',{attrs:{"v":_vm.$v.file,"displayImage":_vm.displayImage,"name":"file"},on:{"input":function($event){return _vm.$v.file.$touch()},"picked":function($event){_vm.HTMLInputElement = $event}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),(_vm.userHasPhoto)?_c('remove-button',{staticClass:"profile-view-edit__remove-button",attrs:{"tooltipContent":"imagem"},on:{"clicked":function($event){return _vm.openPhotoDeleteConfirm()}}}):_vm._e()],1),_c('div',{staticClass:"profile-view-edit__file-label"},[(_vm.file)?_c('small',[_vm._v(_vm._s(_vm.file.name))]):_vm._e()]),_c('form',{staticClass:"profile-view-edit__form",on:{"submit":function($event){$event.preventDefault();}}},[_vm._l((_vm.inputs),function(ref,index){
      var autocomplete = ref.autocomplete;
      var disabled = ref.disabled;
      var icon = ref.icon;
      var id = ref.id;
      var meta = ref.meta;
      var name = ref.name;
      var placeholder = ref.placeholder;
      var type = ref.type;
      var validator = ref.validator;
return _c('form-input',{key:index,attrs:{"type":type,"placeholder":placeholder,"autocomplete":autocomplete,"icon":icon,"id":id,"v":_vm.$v.user[validator],"name":name,"disabled":disabled},model:{value:(_vm.user[meta]),callback:function ($$v) {_vm.$set(_vm.user, meta, $$v)},expression:"user[meta]"}})}),_c('confirm-button',{staticClass:"profile-view-edit__save-button",attrs:{"label":"Salvar","disabled":_vm.disabled},on:{"clicked":function($event){return _vm.submitProfileForm(_vm.authenticatedUser)}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }