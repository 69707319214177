var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"sign-in-with-link-form",on:{"submit":function($event){$event.preventDefault();}}},[_vm._m(0),_c('p',{staticClass:"sign-in-with-link-form__quote"}),_c('div',[_vm._l((_vm.inputs),function(ref,index){
      var autocomplete = ref.autocomplete;
      var autofocus = ref.autofocus;
      var icon = ref.icon;
      var id = ref.id;
      var meta = ref.meta;
      var name = ref.name;
      var placeholder = ref.placeholder;
      var type = ref.type;
      var validator = ref.validator;
return _c('form-input',{key:index,staticClass:"sign-in-with-link-form__input",attrs:{"type":type,"placeholder":placeholder,"autocomplete":autocomplete,"icon":icon,"id":id,"v":_vm.$v.user[validator],"name":name,"autofocus":autofocus},model:{value:(_vm.user[meta]),callback:function ($$v) {_vm.$set(_vm.user, meta, $$v)},expression:"user[meta]"}})}),_c('confirm-button',{staticClass:"sign-in-with-link-form__submit-button",attrs:{"label":"Salvar","disabled":_vm.disabled},on:{"clicked":_vm.submitUserProfile}})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in-with-link-form__title"},[_c('span',[_vm._v("FINALIZE SEU CADASTRO ")])])}]

export { render, staticRenderFns }